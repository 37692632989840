

.photo {
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

.photo img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.textBelowPhoto {
    opacity: 1;
    transition: all 0.2s;

    width: 100%;

    margin: 3% auto;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;

    font-family: 'Caveat', sans-serif;
    color: #48593a;
}
