.polaroid {
    border: 30px solid white;
    border-bottom-width: 80px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);

    margin: 0 auto;
    width: 85vw;
    height: 89vh;

    transition: all 1s;

    position: relative;
    z-index: 200; /* to make the shadow fall over everything */
}

.polaroid.fullScreen {
    width: 100%;
    height: 100vh;
    border-width: 0;

    animation: scaleIn 10s infinite;
}

.polaroid .photo {
    background: url("../public/header/main_photo_landscape.jpg") no-repeat center center;
    background-size: cover;

    width: 100%;
    height: 100%;
}

.polaroid .textInPhoto {
    background: url("../public/header/S_S_2024.png") no-repeat center center;
    background-size: contain;
    mix-blend-mode: overlay;

    position: absolute;
    width: 20%;
    height: 40%;
    bottom: 16%;
    right: 15%;
    z-index: 201;

    opacity: 0;
    transition: all 0.2s;
}

.polaroid.fullScreen .textInPhoto {
    opacity: 1;
}

.polaroid .textBelowPhoto {
    opacity: 1;
    transition: opacity 0.2s;

    width: 100%;
    height: 80px;

    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
}

.polaroid.fullScreen .textBelowPhoto {
    opacity: 0;
}

.polaroid .textBelowPhoto img {
    height: 40px;
    width: 108px;
    margin: 0 auto;
}

.scrollDown {
    position: absolute;
    display: block;
    width: 200px;
    height: 100px;
    bottom: 5%;
    left: calc(50% - 100px);
    opacity: 0;
    text-align: center;
    transition: all 300ms;
}

.scrollDown.active {
    opacity: 0.8;
}

.scrollDown img {
    filter: invert(1);
    width: 60px;
}
.scrollDown p {
    margin-top: 0;
    color: white;
}

/* tablet-like */
@media all and (min-width: 601px) and (max-width: 1200px) {
    .polaroid .textInPhoto {
        bottom: 18%;
        right: 10%;
        width: 23%;
    }
}

/* phone-like */
@media all and (max-width: 600px) {
    .polaroid {
        border-width: 20px;
        border-bottom-width: 80px;
    }
    .polaroid .photo {
        background-position: 40% top;
    }

    .polaroid .textInPhoto {
        bottom: 130px;
        right: 31%;
        width: 35%;
        height: 30%;
        mix-blend-mode: normal;
        transform: none;
    }

    .scrollDown {
        bottom: 70px;
    }
}

