.polaroid {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);

    margin: 0 auto;
    width: 100%;
    height: 100%;

    transition: all 1s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;

    position: relative;
    z-index: 200; /* to make the shadow fall over everything */

    border: solid white;
}
.polaroid.flipped {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}

.polaroid .front {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden !important;
    perspective: 2000px;
}

.polaroid .front * {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden !important;
}

.polaroid .back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden !important;
    perspective: 2000px;
    transform: rotateY(180deg) scale(1.01); /* scale is a hack for Chrome who shows ugly border */
    -webkit-transform: rotateY(180deg) scale(1.01); /* scale is a hack for Chrome who shows ugly border */
    position: absolute;
    top: 0;
    min-height: 100%;
    background: white;
}
